import { useLocation } from '@remix-run/react';
import PullToRefresh from 'pulltorefreshjs';
import { FC, useEffect } from 'react';

const excludePaths = ['/chats', '/work-orders/calendar'];

const PwaPullToRefresh: FC = () => {
  const location = useLocation();
  useEffect(() => {
    const ios = () => {
      if (typeof window === 'undefined' || typeof navigator === 'undefined') return false;

      return /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent || navigator.vendor);
    };

    const isStandalone = () => {
      return 'standalone' in window.navigator && window.navigator['standalone'];
    };

    if (!isStandalone() || !ios()) return;

    PullToRefresh.init({
      // bodyだとモーダルやドロワーの中でスクロールした時にリフレッシュが発生してしまうので、bodyの外側にpage-refresherを設定
      mainElement: '#page-refresher',
      triggerElement: '#page-refresher',
      // 何も表示したくないので、空白を設定
      instructionsPullToRefresh: '&nbsp;',
      instructionsReleaseToRefresh: '&nbsp;',
      instructionsRefreshing: '&nbsp;',
      // disable pull-to-refresh on pages displaying detail and form by ensuring hash is not defined
      shouldPullToRefresh() {
        // 除外パスの場合はリフレッシュしない
        if (excludePaths.some((path) => location.pathname.includes(path))) return false;
        // 詳細ページではリフレッシュしない、かつ、スクロール位置が0の場合のみリフレッシュする
        return !window.scrollY && location.hash === '';
      },
    });

    return () => {
      PullToRefresh.destroyAll();
    };
  }, [location]);

  return <></>;
};

export default PwaPullToRefresh;
